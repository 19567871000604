import { hideLoading, showLoading } from 'react-redux-loading-bar'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import { selectors as TranslationSelectors } from '../../redux/i18n/redux'
import app from '../../config/app'
import routes, { Router } from '../../routes'
import ApiSagas from '../../redux/api/sagas'
import { actions as AuthActions, selectors as AuthSelectors } from '../../redux/auth/redux'
import AuthSagas from '../../redux/auth/sagas'
import { actions as AppActions } from '../../redux/app/redux'
import PaymentQueries from '../../graphql/query/Payment'
import { redirect } from '../../helpers/UrlHelpers'

import { actions } from './redux'


export default class UserRegisterPremiumSagas {

  // eslint-disable-next-line complexity
  static* init(ctx) {
    yield put(showLoading())
    const query = ctx.query

    const me = yield select(AuthSelectors.user)

    if (!me) {
      yield call(AuthSagas.redirectToLogin, ctx.res)
      return {}
    }

    yield all([
      // yield call(UserRegisterPremiumSagas.getPaymentAlias),
      yield call(UserRegisterPremiumSagas.getHostedPayment, query),
      yield call(UserRegisterPremiumSagas.getDiscountCode, query),
    ])

    const seo = yield call(UserRegisterPremiumSagas.transformSeo)

    yield all([
      yield put(AppActions.setSeo(seo)),
      yield put(hideLoading()),
    ])


    // 3D Secure
    if (query?.payment_id && query?.secure === '1') {
      const res = yield call(ApiSagas.query, PaymentQueries.Get3DSecureForm, { paymentId: query.payment_id })

      if (!res.errors) {
        try {
          const form = Buffer.from(res.data.get3DSecureForm, 'base64').toString('utf8')

          yield put(actions.setThreeDSecureForm(form))
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error('UserRegisterPremium: 3D Secure form error', e)
        }
      }
    }

    return {}
  }

  static* getDiscountCode(query) {
    const discountCode = yield call(ApiSagas.query, PaymentQueries.GetDiscountCode)

    if (!discountCode.errors) {
      const prefillDiscountCode = discountCode.data.getDiscountCode

      if (prefillDiscountCode && prefillDiscountCode.params && prefillDiscountCode.params.__typename) {
        delete prefillDiscountCode.params.__typename
      }
      yield put(actions.setPrefillDiscountCode(prefillDiscountCode))
    }
    if (query?.discount || query['amp;discount']) {
      yield put(actions.setPrefillDiscountCode({ code: query?.discount || query['amp;discount'] }))
    }
  }

  // eslint-disable-next-line complexity
  static* getHostedPayment(query) {
    const result = yield call(ApiSagas.query, PaymentQueries.GetHostedPayment, { discount: query?.discount || query['amp;discount'] })

    if (!result.errors) {
      const hostedPaymentParams = result.data.getHostedPayment

      console.log('hostedPaymentParams', hostedPaymentParams)

      if (hostedPaymentParams.params && hostedPaymentParams.params.__typename) {
        delete hostedPaymentParams.params.__typename
      }
      yield put(actions.setHostedPaymentParams(hostedPaymentParams))
    } else {
      // eslint-disable-next-line no-console
      console.log('UserRegisterPremium: Errors', result.errors)
    }
  }

  // eslint-disable-next-line complexity
  static* getPaymentAlias() {
    const result = yield call(ApiSagas.query, PaymentQueries.GetPaymentAlias)

    if (!result.errors) {
      const formParams = result.data.getPaymentAlias

      if (formParams.params && formParams.params.__typename) {
        delete formParams.params.__typename
      }
      yield put(actions.setFormParams(formParams))
    } else {
      // eslint-disable-next-line no-console
      console.log('UserRegisterPremium: Errors', result.errors)
    }
  }

  static* checkDiscountCode({ payload }) {
    const result = yield call(ApiSagas.query, PaymentQueries.CheckDiscountCode, { code: payload.checkDiscount })

    yield put(actions.setDiscountCodeChecked(true))
    if (result.errors && result.errors.length) {
      yield put(actions.checkDiscountError(result.errors))
    } else {
      yield call(redirect, null, Router.getRouteUrl(routes.userRegisterPremium, { discount: payload.checkDiscount }))
      yield put(actions.checkDiscountSuccess(result.data.checkDiscountCode))
    }
  }

  static* createPayment({ payload }) {
    const result = yield call(
      ApiSagas.query,
      PaymentQueries.CreatePaymentWithNewSepaMandate,
      {
        input: {
          ...payload.values,
        },
      }
    )

    if (result.errors) {
      yield put(actions.sendPaymentError(result.errors))
    } else {
      window.location.href = result.data.createPaymentWithNewSepaMandate.merchantRedirectUrl
    }
  }

  static* registerPremiumWithFullDiscount({ payload }) {
    const result = yield call(
      ApiSagas.query,
      PaymentQueries.RegisterPremiumWithFullDiscount,
      {
        discountId: payload.discountId,
      }
    )

    if (result.errors) {
      yield put(actions.registerPremiumWithFullDiscountError(result.errors))
    } else {
      const me = yield select(AuthSelectors.user)

      yield put(AuthActions.setUser({
        ...me,
        company: {
          ...me.company,
          ...result.data.registerPremiumWithFullDiscount.company,
        },
      }))
      yield put(actions.registerPremiumWithFullDiscountSuccess(result.data.registerPremiumWithFullDiscount))
      Router.replaceRoute(routes.userRegisterWelcome.name)
    }
  }

  static* transformSeo() {
    const i18nLang = yield select(TranslationSelectors.lang)
    const i18nStore = yield select(TranslationSelectors.store)
    const titleLabel = i18nStore[i18nLang].translation.SEO_register_premium_title
    const descriptionLabel = i18nStore[i18nLang].translation.SEO_register_premium_description

    const seoProps = {
      title: titleLabel,
      image: '',
      description: descriptionLabel,
      url: `${app.APP_URL}${Router.getRouteUrl(routes.userPayment)}`,
      noindex: true,
    }

    return seoProps
  }

  static* loop() {
    yield all([
      takeLatest(actions.checkDiscount.getType(), UserRegisterPremiumSagas.checkDiscountCode),
      takeLatest(actions.sendPayment.getType(), UserRegisterPremiumSagas.createPayment),
      takeLatest(actions.registerPremiumWithFullDiscount.getType(), UserRegisterPremiumSagas.registerPremiumWithFullDiscount),
    ])
  }

}
