import { createAction, createReducer } from 'redux-act'
import Immutable from 'seamless-immutable'

import Errors from '../../data/models/Errors'
import { requestInitialState, requestReducers } from '../../helpers/ReduxHelpers'


export const key = 'UserRegisterPremium'

//
// Initial state
//

const initialState = {
  checkDiscount: null,
  discountCodeChecked: false,
  formParams: null,
  hostedPaymentParams: null,
  prefillDiscountCode: null,
  sendPayment: requestInitialState(),
  registerPremiumWithFullDiscount: requestInitialState(),
  threeDSecureForm: null,
}

//
// Actions
//

export const actions = {
  checkDiscount: createAction('UserRegisterPremium: checkDiscount', (checkDiscount) => ({ checkDiscount })),
  checkDiscountSuccess: createAction('UserRegisterPremium: checkDiscountSuccess', (data) => data),
  checkDiscountError: createAction('UserRegisterPremium: checkDiscountError', (errors) => Errors(errors)),
  setDiscountCodeChecked: createAction('UserRegisterPremium: setDiscountCodeChecked', (checked) => checked),
  setFormParams: createAction('UserRegisterPremium: setFormParams', (formParams) => ({ formParams })),
  setHostedPaymentParams: createAction('UserRegisterPremium: setHostedPaymentParams', (hostedPaymentParams) => ({ hostedPaymentParams })),
  setPrefillDiscountCode: createAction('UserRegisterPremium: setPrefillDiscountCode', (prefillDiscountCode) => ({ prefillDiscountCode })),
  sendPayment: createAction('UserRegisterPremium: sendPayment', (values) => ({ values })),
  sendPaymentSuccess: createAction('UserRegisterPremium: sendPaymentSuccess', (data) => data),
  sendPaymentError: createAction('UserRegisterPremium: sendPaymentError', (errors) => Errors(errors)),
  //
  registerPremiumWithFullDiscount: createAction('UserRegisterPremium: registerPremiumWithFullDiscount', (discountId) => ({ discountId })),
  registerPremiumWithFullDiscountSuccess: createAction('UserRegisterPremium: registerPremiumWithFullDiscountSuccess', (data) => data),
  registerPremiumWithFullDiscountError: createAction('UserRegisterPremium: registerPremiumWithFullDiscountError', (errors) => Errors(errors)),
  //
  setThreeDSecureForm: createAction('UserRegisterPremium: setThreeDSecureForm', (threeDSecureForm) => ({ threeDSecureForm })),
}

//
// Reducer
//

export const reducer = createReducer({
  ...requestReducers(actions, 'checkDiscount'),
  [actions.setDiscountCodeChecked]: (state, discountCodeChecked) => Immutable.merge(state, { discountCodeChecked }),
  [actions.setFormParams]: (state, { formParams }) => Immutable.static.merge(state, { formParams }),
  [actions.setHostedPaymentParams]: (state, { hostedPaymentParams }) => Immutable.static.merge(state, { hostedPaymentParams }),
  // eslint-disable-next-line max-len
  [actions.setPrefillDiscountCode]: (state, { prefillDiscountCode }) => Immutable.static.merge(state, { prefillDiscountCode }),
  //
  ...requestReducers(actions, 'sendPayment'),
  //
  ...requestReducers(actions, 'registerPremiumWithFullDiscount'),
  //
  [actions.setThreeDSecureForm]: (state, { threeDSecureForm }) => Immutable.merge(state, { threeDSecureForm }),
}, initialState)

//
// Selectors
//

const root = (state) => state[key]
const checkDiscount = (state) => root(state).checkDiscount
const discountCodeChecked = (state) => root(state).discountCodeChecked
const formParams = (state) => root(state).formParams
const hostedPaymentParams = (state) => root(state).hostedPaymentParams
const prefillDiscountCode = (state) => root(state).prefillDiscountCode
const sendPayment = (state) => root(state).sendPayment
const registerPremiumWithFullDiscount = (state) => root(state).registerPremiumWithFullDiscount
const threeDSecureForm = (state) => root(state).threeDSecureForm

export const selectors = {
  checkDiscount,
  discountCodeChecked,
  formParams,
  prefillDiscountCode,
  sendPayment,
  registerPremiumWithFullDiscount,
  threeDSecureForm,
  hostedPaymentParams,
}
