import gql from 'graphql-tag'


export const getHostedPayment = gql`
    query GetHostedPayment($discount: String) {
        getHostedPayment(discount:$discount) {
            url
            amount
            params {
                ACCEPTURL
                AMOUNT
                EXCEPTIONURL
                LANGUAGE
                ORDERID
                PSPID
                SHASIGN
                CN
                CURRENCY
                PARAMPLUS
            }
        }
    }
`
